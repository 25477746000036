(function (jQuery) {

    window.$ = jQuery.noConflict();

    var resizeTimer, currentVideo, $panels, $activePanel;
    

    $(window).on('resize', function(e) {
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(function() {
            calcPanels();
        }, 200);
    });

    function calcPanels(){

        $window_width = $('.body-content').eq(0).width();
        
        $('.panels').css('width', $window_width);
        $('.panels .background, .panels .background-fade').css('width', $window_width);

        $('.panel').each(function(i, el){
            oldTransition = $(el).css('transition'); $(el).css('transition', 'none');
            oldBgTransition = $(el).find('.background').css('transition'); $(el).find('.background').css('transition', 'none');
            if(i > 0)
                $(el).find('.background').css('left', i * (-1 * ($window_width/4) ) );
                setTimeout(function(){
                    $(el).css('transition', oldTransition);
                    $(el).find('.background').css('transition', oldBgTransition);
                }, 100);
            
            
        });
    };

    (function bindEvents() {

        $panels = $(document).find('.panels');

        $(document).on('click', '.panel-control-play', playButtonClickHandler);
        $(document).on('click', '.panel-control-pause', pauseButtonClickHandler);
        $(document).on('click mouseenter mouseleave', '.panel-link', videoPreviewHoverOverHandler);
        $(document).on('click', '.panels .close-button', closeButtonClickHandler);
    }());

    function playButtonClickHandler(e) {

        var $thisPanel = $(this).closest('.panel');

        $thisPanel.parent().addClass('panels-active');
        $thisPanel.toggleClass('active');
        $thisPanel.siblings('.panel').each(function( i, elem){
            $(elem).removeClass('active');
            $(elem).find('video').get(0).pause();
        });

        $thisPanel.find('video').get(0).play();
    }

    function pauseButtonClickHandler(e) {

        var $thisPanel = $(this).closest('.panel');

        $thisPanel.parent().removeClass('panels-active');
        $thisPanel.toggleClass('active');

        $thisPanel.find('video').get(0).pause();
    }

    function videoPreviewHoverOverHandler(e) {

        $targ = $(this).attr('id');
        $el = $(this).parent().parent();

        var $target = $(document).find('.panel[data-panel="' + $targ + '"]');

        if(e.type == "mouseenter" || "mouseleave"){
            
            
            
            currentVideo = $target.find('video').get(0);

            $target.toggleClass('hover');

            if(e.type == 'click'){
                console.log("click")
                showFullVideo();
            }
            else if(e.type == "mouseenter") {
                currentVideo.play()
           
            }
            else {
               
                if( !$target.hasClass('active') ) currentVideo.pause()
            }
        }
    }

    function closeButtonClickHandler(e) {

        e.preventDefault();

        $panels.removeClass('panels-active');
        $panels.find('.panel.active video').get(0).pause();
        $panels.find('.panel.active').removeClass('active');
        
    }
 
    function showFullVideo() {
        $el.addClass('panels-active');
        $el.find('.panel').removeClass('active');
        $el.find('.panel[data-panel="' + $targ + '"]').toggleClass('active');
    }

    calcPanels();

    $(document).ready(function(el){
        

        $(document).on('click', 'button', function(e){
            window.location.href = '/game';
        })

    })

})(jQuery);