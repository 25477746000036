// Enclosed within an anonymous function so that you can always reference jQuery
// with $, even when in .noConflict() mode
($ => {
  // Sweepstakes landing module
  const SweepstsakesLanding = (() => {
    // Our global variables
    let rellax;
    let rellaxActive = false;

    // Module init method
    const init = () => {

      const mySwiper = new Swiper('.prizes-container', {
        direction: 'horizontal',
        loop: true,
        slidesPerView: 3,
        centeredSlides: true,
        autoplay: {
          delay: 3000
        },
        pagination: {
          el: '.prizes-pagination'
        },
        breakpointsInverse: true,
        breakpoints: {
          640: {
            slidesPerView: 4
          },
          768: {
            slidesPerView: 4
          }
        },
        spaceBetween: 20,
        navigation: {
          nextEl: '.prizes-button-next',
          prevEl: '.prizes-button-prev'
        }
      });

      const headerCarousel = new Swiper('.hero-container', {
        direction: 'horizontal',
        loop: true,
        autoplay: {
            delay: 3000
          },
      });

      $(window).on('load resize', resize);

      AOS.init();
    };

    const resize = () => {
      if (Modernizr.mq('(min-width: 768px)')) {
        if (!rellaxActive) {
          rellax = new Rellax('.rellax');
          rellaxActive = true;
        }
      } else if (rellax) {
        rellax.destroy();
      }
    };

    // Reveal our modules public methods
    return { init };
  })();

  // Init our module
  $(SweepstsakesLanding.init());
})(jQuery);

